<template>
  <v-dialog v-model="confirmation.modal" persistent max-width="360">
    <v-card>
      <v-card-title class="headline primary--text">
        {{ $phraser.set('confirm phone') }}
      </v-card-title>
      <v-form v-if="!loading && !done" ref="form" @submit.prevent="submit">
        <v-card-text class="text-center">
          <div>
            {{ $t('content.sentconfirmmessage') }}
            <h4 class="mt-1">
              {{ confirmation.phone }}
            </h4>
          </div>
          <v-divider class="my-2" />
          <div class="">
            {{ $t('content.entercode') }}
            <base-smart-input
              :model.sync="code"
              :label="$phraser.set(`code`)"
              length="6"
              numberic
              type="tel"
              max-width="150"
              centered
              class="mx-auto"
            />
          </div>
          <v-divider class="my-2" />
          <div v-if="resendTimeLeft" class="blue--text text-body-1">
            {{ $t('form.resendable_in') }} {{ resendTimeLeft }}
          </div>
          <div v-else class="red--text text-caption">
            <v-btn
              color="secondary"
              small
              :loading="resendLoading"
              @click="resendCode"
            >
              {{ $t('content.send_new_code') }}
            </v-btn>
          </div>
          <div v-if="timeLeft" class="red--text text-caption mt-3">
            {{ $t('content.codelosevalid') }} {{ timeLeft }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1" type="submit">
            {{ $t('confirm') }}
          </v-btn>
          <v-spacer />
          <v-btn color="grey" text @click="cancel">
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-card-text v-else-if="loading" class="pa-3 loading">
        <div class="d-flex justify-center pt-5">
          <v-progress-circular :size="80" color="primary" indeterminate />
        </div>
      </v-card-text>
      <v-card-text v-else-if="done" class="pa-3 done">
        <div class="d-flex justify-center pt-5">
          <v-icon color="success">
            mdi-thumb-up
          </v-icon>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
export default {
  name: 'PhoneConfirmation',
  data: () => ({
    // code: '',
    loading: false,
    resendLoading: false,
    timetosubmit: null,
    done: false,
  }),

  computed: {
    code: {
      get () {
        return this.$store.getters['confirmphone/code']
      },
      set (code) {
        this.$store.dispatch('confirmphone/setCode', code)
      },
    },
    confirmation () {
      return this.$store.getters['confirmphone/get']
    },
    timeLeft () {
      if (!this.confirmation.timeLeft) return false
      return moment
        .utc(
          moment
            .duration(this.confirmation.timeLeft, 'seconds')
            .as('milliseconds'),
        )
        .format('mm:ss')
    },
    resendTimeLeft () {
      if (!this.confirmation.resendTimeLeft) return false
      return moment
        .utc(
          moment
            .duration(this.confirmation.resendTimeLeft, 'seconds')
            .as('milliseconds'),
        )
        .format('mm:ss')
    },
  },

  created () {
    moment.locale(this.$i18n.locale)
  },

  methods: {
    cancel () {
      this.$store.dispatch('confirmphone/cancel')
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.loading = true
        await this.$store.dispatch('confirmphone/checkCode')
        if (this.confirmation.temporary) {
          this.done = true
          this.loading = false
          setTimeout(() => {
            this.$store.dispatch('confirmphone/close')
            this.done = false
          }, 2000)
        } else {
          this.loading = false
        }
      }
    },
    async resendCode () {
      this.resendLoading = true
      await this.$store.dispatch('confirmphone/resend')
      this.resendLoading = false
    },
    throttle (callback, wait = 2000) {
      this.timetosubmit = +new Date() + wait
      setTimeout(() => {
        if (+new Date() >= this.timetosubmit) {
          callback()
        }
      }, wait)
    },
  },
}
</script>

<style scoped lang="sass">
.loading
  height: 180px
.done
  height: 180px
  .v-icon
    font-size: 96px
</style>
